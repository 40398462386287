<template>
  <div>
    <vs-table stripe :multiple="false" v-model="selected" :sst="true" :max-items="50" :data="list" noDataText="">
      <template slot="thead">
        <vs-th sort-key="topic">Şikayet Başlığı</vs-th>
        <vs-th sort-key="user">Şikayet Eden</vs-th>
        <vs-th sort-key="description">Açıklama</vs-th>
        <vs-th sort-key="createDate">Tarih</vs-th>
      </template>
      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td id="topic">
              {{ tr.complaint_topic.topic }}
            </vs-td>
            <vs-td id="user">
              {{ tr.complainantUserData.username }}
            </vs-td>
            <vs-td id="description">
              {{ tr.description }}
            </vs-td>
            <vs-td id="createDate">
              <p class="font-medium w-24">
                {{ tr.createDate | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.createDate | moment('HH:mm') }}
              </p>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  props: ['list'],
  data: () => ({
    selected: []
  })
};
</script>
