var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "complaint-target--wrapper p-4 bg-white rounded" },
        [
          _vm.target
            ? _c(
                "div",
                {
                  staticClass:
                    "header mb-2 pb-2 border-0 border-solid border-b-2 border-gray-200 flex justify-between"
                },
                [
                  _c("h3", { staticClass: "text-base" }, [
                    _vm._v("Şikayet Edilen " + _vm._s(_vm.complaintType))
                  ]),
                  _c(
                    "vx-tooltip",
                    { attrs: { text: "İşlemler" } },
                    [
                      _c("vs-button", {
                        staticClass: "btn btn--action",
                        attrs: {
                          to: _vm.contentLink,
                          color: "secondary",
                          size: "small",
                          type: "gradient",
                          icon: "open_in_browser"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.target && _vm.$query.type === "entry"
            ? _c("div", [
                _vm.target.topic
                  ? _c("div", { attrs: { id: "dio-title" } }, [
                      _c("strong", { attrs: { id: "entrytopic" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.target.topic.topic) +
                            "\n        "
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("div", {
                  staticClass: "mt-3 whitespace-pre-wrap ",
                  attrs: { id: "entryhtml" },
                  domProps: { textContent: _vm._s(_vm.target.entry.entryData) }
                }),
                _vm.target.entry.entryImageUrls &&
                _vm.target.entry.entryImageUrls.length
                  ? _c(
                      "div",
                      { staticClass: "flex break-word my-2" },
                      _vm._l(_vm.target.entry.entryImageUrls, function(
                        img,
                        index
                      ) {
                        return _c("div", { key: index, staticClass: "w-1/5" }, [
                          _c("img", {
                            staticClass: "rounded image-entry pl-0 p-2",
                            attrs: { src: _vm.getImageUrl(img, "75x75") }
                          })
                        ])
                      }),
                      0
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.target && _vm.$query.type === "topic"
            ? _c("div", [_vm._v(_vm._s(_vm.target.topic))])
            : _vm._e(),
          _vm.target && _vm.$query.type === "user"
            ? _c("div", [
                _c("div", { staticClass: "flex flex-col" }, [
                  _c("div", { staticClass: "username" }, [
                    _c("strong", [_vm._v("Kullanıcı Adı:")]),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.target.username) +
                        "\n        "
                    )
                  ]),
                  _c("div", { staticClass: "email" }, [
                    _c("strong", [_vm._v("Email:")]),
                    _vm._v(
                      "\n          " + _vm._s(_vm.target.email) + "\n        "
                    )
                  ])
                ])
              ])
            : _vm._e()
        ]
      ),
      _c("ComplaintDetailList", {
        staticClass: "mt-10",
        attrs: { list: _vm.list }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }